import { TrashIcon } from "@heroicons/react/solid";
import React from "react";
import { useDispatch } from "react-redux";
import { eventStartDelete } from "../../actions/calendar";

export const DeleteEventFab = () => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(eventStartDelete());
  };

  return (
    <button
      className="absolute left-2 bottom-2 w-12 h-12 p-2 bg-red-500 text-white rounded-full shadow-lg hover:bg-white hover:text-red-500 transition-colors"
      onClick={handleDelete}
    >
      <TrashIcon />
    </button>
  );
};
